<template>
  <div>
    <div v-if="isMobile===false">
      <div class="Login">
        <div class="center">
          <div class="centerWidth">
            <div class="right">
              <div class="tabsBar">
                <div
                    class="barItem"
                    @click="barChange()"
                    :class="isbarActive == 1 ? 'activebarCls' : ''"
                >
                  我要找工作
                </div>
                <div
                    class="barItem"
                    @click="barChange()"
                    :class="isbarActive == 2 ? 'activebarCls' : ''"
                >我要招聘
                </div>
              </div>
              <div class="tabs">
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 1 ? 'activeCls' : ''"
                >
                  账号登录
                </div>
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 2 ? 'activeCls' : ''"
                >
                  短信登录
                </div>
              </div>
              <div class="tabContain">
                <div class="items" :class="isActive == 1 ? 'activeCls' : ''">
                  <el-input
                      placeholder="请输入邮箱/手机号"
                      v-model="accountLogin.username"
                  ></el-input>
                  <el-input
                      placeholder="请输入密码"
                      v-model="accountLogin.password"
                      show-password
                  ></el-input>
                </div>
                <div class="items" :class="isActive == 2 ? 'activeCls' : ''">
                  <el-input
                      placeholder="请输入手机号"
                      v-model="phoneLogin.phone"
                  ></el-input>
                  <div class="flex">
                    <el-input
                        placeholder="请输入验证码"
                        v-model="phoneLogin.code"
                    ></el-input>
                    <el-button
                        type="text"
                        :disabled="disabled"
                        class="setCode"
                        @click="getCode()"
                    >{{ setCode }}</el-button
                    >
                  </div>
                </div>
                <div class="submit" @click="submit()">登录</div>
                <div class="flex">
                  <div class="register" @click="register()">
                    没有账号？<span>立即注册</span>
                  </div>
                  <span class="forgetPassword" style="color: #BF0022" @click="forget()">
                    忘记密码>>
                  </span>
                </div>
                <div class="weixin" @click="wechat()">
                  <img loading="lazy" src="@/assets/wechart.png" alt="" />
                  <span>使用社交账号登录</span>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile===true">
      <div class="Login1">
        <div class="header1">
          <div class="centerWidth">
            <router-link to="/" class="login">
              <img loading="lazy" src="@/assets/logo.png" alt="" />
            </router-link>
            <div class="name">账号登录</div>
          </div>
        </div>
        <div class="center1">
          <!-- <img loading="lazy" src="../assets/login.png" alt="" /> -->
          <div class="centerWidth">
            <div class="right">
              <div class="tabs">
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 1 ? 'activeCls' : ''"
                >
                  账号登录
                </div>
                <div
                    class="tabsItem"
                    @click="tabsChange()"
                    :class="isActive == 2 ? 'activeCls' : ''"
                >
                  短信登录
                </div>
              </div>
              <div class="tabContain">
                <div class="items" :class="isActive == 1 ? 'activeCls' : ''">
                  <el-input
                      placeholder="请输入邮箱/手机号"
                      v-model="accountLogin.username"
                  ></el-input>
                  <el-input
                      placeholder="请输入密码"
                      v-model="accountLogin.password"
                      show-password
                  ></el-input>
                </div>
                <div class="items" :class="isActive == 2 ? 'activeCls' : ''">
                  <el-input
                      placeholder="请输入手机号"
                      v-model="phoneLogin.phone"
                  ></el-input>
                  <div class="flex">
                    <el-input
                        placeholder="请输入验证码"
                        v-model="phoneLogin.code"
                    ></el-input>
                    <el-button
                        type="text"
                        :disabled="disabled"
                        class="setCode"
                        @click="getCode()"
                    >{{ setCode }}</el-button
                    >
                  </div>
                </div>
                <div class="submit" @click="submit()">登录</div>
                <div class="flex">
                  <div class="register" @click="register()">
                    没有账号？<span>立即注册</span>
                  </div>
                  <span class="forgetPassword" style="color: #BF0022" @click="forget()">
                    忘记密码>>
                  </span>
                </div>
                <!--                <div class="weixin" @click="wechat()">-->
                <!--                  <span>使用社交账号登录</span>-->
                <!--                  <img loading="lazy" src="../assets/wechart.png" alt="" />-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import SimpleBottom from "@/components/SimpleBottom.vue";

export default {
  name: "Login",
  // components: { SimpleBottom },
  data() {
    return {
      Bar:"我要找工作",
      isMobile:false,
      languageSelect:'',
      baseURL: this.$utils.baseURL,
      isActive: 1,
      isbarActive:1,
      disabled: false,
      accountLogin: {
        username: "",
        password: "",
      },
      phoneLogin: {
        phone: "",
        code: "",
      },
      setCode: "发送验证码",
      setCodeEn: "Verification code",
    };
  },
  computed: {},
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
  },
  methods: {
    register(){

    },
    forget(){

    },
    tabsChange() {
      // this.isActive = this.isActive == 1 ? 2 : 1;
    },
    barChange(){
      // this.isbarActive=this.isbarActive==1?2:1
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      // if (!this.phoneLogin.phone) {
      //   this.$message.error("请输入手机号");
      //   return false;
      // }
      // const reg = /^\d{6,13}$/;
      // if (!reg.test(this.phoneLogin.phone)) {
      //   this.$message.error("请输入正确的手机号");
      //   return false;
      // }
      // let data = {
      //   phone: this.phoneLogin.phone,
      //   event:'login',
      // };
      // phoneCode(data).then((res) => {
      //   this.$message({
      //     message: res.msg,
      //     type: "success",
      //   });
      //   this.tackBtn(); //验证码倒数60秒
      // });
    },
    getCodeEn() {
      // if (!this.phoneLogin.phone) {
      //   this.$message.error("Please enter your phone number");
      //   return false;
      // }
      // const reg = /^\d{6,13}$/;
      // if (!reg.test(this.phoneLogin.phone)) {
      //   this.$message.error("Please enter the correct phone number");
      //   return false;
      // }
      // let data = {
      //   phone: this.phoneLogin.phone,
      //   event:'login',
      // };
      // phoneCode(data).then((res) => {
      //   this.$message({
      //     message: res.msg,
      //     type: "success",
      //   });
      //   this.tackBtnEn(); //验证码倒数60秒
      // });
    },
    tackBtn() {
      // //验证码倒数60秒
      // let time = 60;
      // let timer = setInterval(() => {
      //   if (time == 0) {
      //     clearInterval(timer);
      //     this.setCode = "获取验证码";
      //     this.disabled = false;
      //   } else {
      //     this.disabled = true;
      //     this.setCode = time + "秒后重试";
      //     time--;
      //   }
      // }, 1000);
    },
    tackBtnEn() {
      // //验证码倒数60秒
      // let time = 60;
      // let timer = setInterval(() => {
      //   if (time == 0) {
      //     clearInterval(timer);
      //     this.setCodeEn = "Get verification code";
      //     this.disabled = false;
      //   } else {
      //     this.disabled = true;
      //     this.setCodeEn =  "Try again in "+time +" seconds";
      //     time--;
      //   }
      // }, 1000);
    },
    submit() {
      // if (this.isActive == 1) {
      //   if (!this.accountLogin.username) {
      //     this.$message.error("请输入邮箱/手机号");
      //     return false;
      //   }
      //   if (!this.accountLogin.password) {
      //     this.$message.error("请输入密码");
      //     return false;
      //   }
      //   this.$axios.post(
      //       "/system/job_user/login",
      //       {
      //         username: this.accountLogin.username,
      //         password: this.accountLogin.password,
      //       }).then((res) => {
      //     // console.log("---------------")
      //     // console.log(res.data)
      //     localStorage.setItem("access_token", res.data.data.token);
      //     this.$store.state.access_token = res.data.data.token;
      //     localStorage.setItem("typeInfo", res.data.data.type);
      //     this.$store.state.typeInfo = res.data.data.type;
      //     localStorage.setItem("userId", res.data.data.userId);
      //     this.$store.state.userId = res.data.data.userId;
      //     this.$router.go(-1);
      //     this.$message({
      //       message: "登录成功！", //提示的信息
      //       type: "success", //类型是成功
      //     });
      //   }).catch(err=>{
      //     console.log(err)
      //   });
      // } else {
      //   if (!this.phoneLogin.phone) {
      //     this.$message.error("请输入手机号");
      //     return false;
      //   }
      //   const reg = /^\d{6,13}$/;
      //   if (!reg.test(this.phoneLogin.phone)) {
      //     this.$message.error("请输入正确的手机号");
      //     return false;
      //   }
      //   if (!this.phoneLogin.code) {
      //     this.$message.error("请输入验证码");
      //     return false;
      //   }
      //   phoneLogin(this.phoneLogin).then((res) => {
      //     localStorage.setItem("access_token", res.data.data.token);
      //     this.$store.state.access_token = res.data.data.token;
      //     localStorage.setItem("typeInfo", res.data.data.type);
      //     this.$store.state.typeInfo = res.data.data.type;
      //     localStorage.setItem("userId", res.data.data.userId);
      //     this.$store.state.userId = res.data.data.userId;
      //     // let type = res.data.data.type;
      //     this.$message({
      //       message: "登录成功！", //提示的信息
      //       type: "success", //类型是成功
      //       duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
      //       onClose: () => {
      //         //跳转页面或执行方法
      //         // if (type == 0) {
      //         //   this.$router.push({ name: "BasicInformation" });
      //         // } else {
      //         this.$router.go(-1);
      //         // }
      //       },
      //     });
      //   }).catch(err=>{
      //     console.log(err)
      //   });
      // }
    },
    submitEn() {
      // if (this.isActive == 1) {
      //   if (!this.accountLogin.username) {
      //     this.$message.error("Please enter your email/phone number");
      //     return false;
      //   }
      //   if (!this.accountLogin.password) {
      //     this.$message.error("Please enter password");
      //     return false;
      //   }
      //   userLogin(this.accountLogin).then((res) => {
      //
      //     localStorage.setItem("access_token", res.data.token);
      //     this.$store.state.access_token = res.data.token;
      //     localStorage.setItem("typeInfo", res.data.type);
      //     this.$store.state.typeInfo = res.data.type;
      //     localStorage.setItem("userId", res.data.userId);
      //     this.$store.state.userId = res.data.userId;
      //     // let type = res.data.type;
      //     this.$message({
      //       message: "Login successful！", //提示的信息
      //       type: "success", //类型是成功
      //       duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
      //       onClose: () => {
      //         //跳转页面或执行方法
      //         // if (type == 0) {
      //         //   this.$router.push({ name: "BasicInformation" });
      //         // } else {
      //         this.$router.go(-1);
      //         // }
      //       },
      //     });
      //   }).catch(error=>{
      //     this.$message({
      //       message: "The user does not exist or the password is incorrect！",
      //       type: "error",
      //       duration: 1200,
      //     })
      //     console.log(error)
      //   })
      //
      //   ;
      // } else {
      //   if (!this.phoneLogin.phone) {
      //     this.$message.error("Please enter your phone number.");
      //     return false;
      //   }
      //   const reg = /^\d{6,13}$/;
      //   if (!reg.test(this.phoneLogin.phone)) {
      //     this.$message.error("Please enter the correct phone number");
      //     return false;
      //   }
      //   if (!this.phoneLogin.code) {
      //     this.$message.error("Please enter the verification code");
      //     return false;
      //   }
      //   phoneLogin(this.phoneLogin).then((res) => {
      //     localStorage.setItem("access_token", res.data.token);
      //     this.$store.state.access_token = res.data.token;
      //     localStorage.setItem("typeInfo", res.data.type);
      //     this.$store.state.typeInfo = res.data.type;
      //     localStorage.setItem("userId", res.data.userId);
      //     this.$store.state.userId = res.data.userId;
      //     // let type = res.data.type;
      //     this.$message({
      //       message: "Login successful！", //提示的信息
      //       type: "success", //类型是成功
      //       duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
      //       onClose: () => {
      //         //跳转页面或执行方法
      //         // if (type == 0) {
      //         //   this.$router.push({ name: "BasicInformation" });
      //         // } else {
      //         this.$router.go(-1);
      //         // }
      //       },
      //     });
      //   });
      // }
    },
    wechat() {
      // // let url = window.location.href;
      // let url = this.baseURL + "/success";
      // getWxCode(url).then((res) => {
      //   console.log(res, "res");
      //   window.location.href = res.data.url
      // });
    },
  },
  mounted() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
};
</script>
<style lang="scss" scoped>

.center {
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .centerWidth {
    display: flex;
    align-items: center;
    justify-content: center;

    .right {
      height: 100%;
      width:100%;
      background: #fff;
      box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
      border-radius: 4px 4px 4px 4px;
      padding: 14px 24px;
      cursor: pointer;
      .tabsBar{
        height: 36px;
        width:100%;
        text-align: center;
        padding: 3px;
        margin-bottom: 16px;
        background-color: #f6f6f6;
        display: flex;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        .barItem{
          font-size: 12px;
          width: 112px;
          height: 30px;
          padding: 6px;
          margin: auto;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
        }
        .activebarCls{
          font-size: 12px;
          width: 112px;
          height: 30px;
          padding-top: 6px;
          margin: auto;
          text-align: center;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background-color: white;
          border-radius: 5px;
          color: var(--main-color);
        }
      }

      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #eeeeee;

        .tabsItem {
          // height: 29px;
          // line-height: 29px;
          margin: 0 16px;
          font-size: 12px;
          padding-bottom: 10px;
          color: #bbbbbb;
          cursor: pointer;
        }
        .tabsItem.activeCls {
          font-size: 14px;
          color: #333333;
          position: relative;
        }

        .tabsItem.activeCls:after {
          content: "";
          width: 100%;
          height: 2px;
          background: var(--main-color);
          position: absolute;
          left: 0;
          bottom: -1px;
        }
      }
      .tabContain {
        width: 100%;
        .items {
          display: none;
          > div {
            margin-top: 1.4vh;
          }
          ::v-deep {
            .el-input__inner {
              // height: 100%;
              line-height: 30px;
            }.el-input__icon {
               line-height:36px;
             }
            .el-input.is-active .el-input__inner,
            .el-input__inner:focus {
              height: 36px;
              border-color: #dcdfe6;
            }
            .el-input__inner{
              height: 34px;
            }
          }
          .el-input {
            width: 100%;
            height: 36px;
            line-height: 30px;
            font-size: 12px;
            border: 1px solid #d0d0d0;
            border-radius: 4px;
          }
          .el-input:place-holder {
            color: #bbbbbb;
          }
          .flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            ::v-deep .el-button{
              padding:0.8vh 0.7vw !important;
            }
            ::v-deep {

              .setCode {
                width: 6vw;
                height: 36px;
                // line-height: 50px;
                text-align: center;
                border-radius: 3px;
                background: var(--main-color);
                color: #fff;
                font-size: 12px;
                margin-left: 0.5vw;

              }
            }

            .el-input {
              flex: 1;
            }
          }
        }

        .items.activeCls {
          display: block;
        }

        .submit {
          margin-top: 20px;
          height: 36px;
          line-height:36px;
          border-radius: 3px;
          background: var(--main-color);
          color: #fff;
          text-align: center;
          cursor: pointer;
          font-size: 13.5px;
        }
        .flex {
          display: flex;
          justify-content: space-between;
          margin-top:8px;
          font-size: 12px;
          line-height: 15px;
          color: #333333;
          a.forgetPassword,
          .register span {
            color: var(--main-color);
          }
        }
        .weixin {
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-top: 20px;
          display: flex;
          color: #555555;
          font-size: 12px;
          img {
            width: 22px;
            height: 22px;
            margin-right: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
input {
  border: none;
  outline: none;
}
</style>
