<template>
  <div>
    <div v-if="isMobile===false" class="AiPop" v-click-outside="handleBlur">
      <div class="buttonPop" @click="AiPopShow = !AiPopShow">
        <img loading="lazy" src="@/assets/ai.png"/>
      </div>
      <div class="pop" v-if="AiPopShow">
        <div class="box" v-if="languageSelect==='zh'">
          <div class="content">
            <div class="communicate" ref="messageContainer">
              <div class="atalk">
                <div class="AIphoto">
                </div>
                <span id="asay" style="display: flex">
              <div v-if="languageSelect==='zh'" class="talk" id="whose1">
                 <div class="triangle"></div>
                您好，我是您的AI简历小助手小安，请问有什么关于简历的问题，我会尽力帮您解答！
              </div>

            </span>
              </div>

              <div class="talk-show">
                <div v-for="message in messages" :key="message.id" class="message"
                     :class="message.sender === 'user' ? 'user' : 'ai'">
                  <div v-if="message.sender === 'user'">
                    <div class="btalk">
                      <div style="display: flex;max-width: 80%">
                        <div><img class="userPhoto" :src="userInfo.avatar ? baseURL + userInfo.avatar : avator" alt="User Avatar"/></div>
                        <div style="width: 100%;margin-left: 10px">
                                <span><div class="arrow-content">
                                   <div class="triangle1"></div>
                                  {{ message.text }}</div></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="atalk">
                      <div class="AIphoto"></div>
                      <span id="asay" style="">
                      <div class="talk" id="whose1">
                            <div class="triangle"></div>
                        {{ message.answer }}
                      </div>
                        <!--                      <div v-for="(item2, index) in message.recourse" :key="index">-->
                        <!--                        <div class="con_que" @click="clickRobot(item2)"-->
                        <!--                        >-->
                        <!--                          <div class="czkj-question-msg">-->
                        <!--                            {{ item2.index }}-->
                        <!--                            {{ item2.title }}-->
                        <!--                          </div>-->
                        <!--                        </div>-->
                        <!--                      </div>-->
                    <button v-if="isTextDisplayed && message.deny === 0" @click="denyChange(message)" data-info="info"
                            class="deny" id="deny">
                     <span class="hover-text" style="display: none"></span>
                    </button>
                    <button v-if="isTextDisplayed && message.deny === 1" @click="denyChange(message)"
                            data-info="{'change': 1}"
                            class="denyReally" id="deny">
                      <span class="hover-text" style="display: none"></span>
                    </button>
                    </span>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="talk_input">
              <div>
                    <textarea
                        v-if="languageSelect==='zh'"
                        placeholder="请输入需要咨询的问题"
                        class="talk_word"
                        style="border:none;min-height:80px;padding:5px 7vh 5px 5px;overflow:auto;font-size: 16px"
                        maxlength="3000"
                        id="text"
                        :rows="1"
                        v-model="newMessage"
                        @keyup.enter="sendMessage()"
                    ></textarea>
                <el-button class="talk_sub" :disabled="isSendingMessage" @keydown.enter="sendMessage()"
                           @click="sendMessage()" type="primary"></el-button>
              </div>
            </div>

          </div>
          <div id="content" class="content" ref="content">
            <div v-for="(item, index) in info" :key="index">
              <div class="info_r info_default" v-if="item.type == 'leftinfo'">
              <span class="circle circle_r">
                <img loading="lazy" src="@/assets/ai.png" alt=""/>
              </span>
                <div class="con_r con_text">
                  <div>{{ item.answer }}</div>
                  <div v-for="(item2, index) in item.question" :key="index">
                    <div
                        class="con_que"
                        @click="clickRobot(item2.content, item2.id)"
                    >
                      <div class="czkj-question-msg">
                        {{ item2.index }}
                        {{ item2.content }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="time_r">{{ item.time }}</div>
              </div>
              <div class="info_l" v-if="item.type == 'rightinfo'">
              <span class="circle circle_l">
                <img
                    :src="item.athor.avator"
                    alt="User Avatar"
                    style="border-radius: 45px"
                />
              </span>
                <div class="con_l con_text">{{ item.content }}</div>
                <div class="time_l">{{ item.time }}</div>
              </div>
            </div>
          </div>
          <div class="setproblem">
          <textarea
              placeholder="请输入需要咨询的问题"
              style=""
              :disabled="isSendingMessage"
              id="text"
              :rows="1"
              v-model="customerText"
              @keyup.enter="sentMsg()"
          ></textarea>
            <el-button @click="sentMsg()" class="buttonsend">
              <span style="vertical-align: 4px">发送</span>
            </el-button>
          </div>
        </div>
        <div class="box" v-if="languageSelect==='en'">
          <div id="content" class="content" ref="content">
            <div v-for="(item, index) in infoEn" :key="index">
              <div class="info_r info_default" v-if="item.type === 'leftinfo'">
              <span class="circle circle_r">
                <img loading="lazy" src="@/assets/ai.png" alt=""/>
              </span>
                <div class="con_r con_text">
                  <div>{{ item.answer }}</div>
                  <div v-for="(item2, index) in item.question" :key="index">
                    <div class="con_que">
                      <div class="czkj-question-msg">
                        {{ item2.index }}
                        {{ item2.content }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="time_r">{{ item.time }}</div>
              </div>
              <div class="info_l" v-if="item.type === 'rightinfo'">
              <span class="circle circle_l">
                <img
                    :src="item.athor.avator"
                    alt="User Avatar"
                    style="border-radius: 45px"
                />
              </span>
                <div class="con_l con_text">{{ item.content }}</div>
                <div class="time_l">{{ item.time }}</div>
              </div>
            </div>
          </div>
          <div class="setproblem">
          <textarea
              placeholder="Please enter the question"
              style=""
              :disabled="isSendingMessage"
              id="text"
              :rows="1"
              v-model="customerTextEn"
              @keyup.enter="sentMsgEn()"
          ></textarea>
            <el-button @click="sentMsgEn()" class="buttonsend">
              <span style="vertical-align: 4px">Send</span>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile===true" class="AiPop1" v-click-outside="handleBlur">
      <div class="buttonPop" @click="AiPopShow = !AiPopShow">
        <img loading="lazy" src="@/assets/ai.png"/>
      </div>
      <div class="pop" v-if="AiPopShow">
        <div class="box" v-if="languageSelect==='zh'">
          <div id="content" class="content" ref="content">
            <div v-for="(item, index) in info" :key="index">
              <div class="info_r info_default" v-if="item.type == 'leftinfo'">
              <span class="circle circle_r">
                <img loading="lazy" src="@/assets/ai.png" alt=""/>
              </span>
                <div class="con_r con_text">
                  <div>{{ item.answer }}</div>
                  <div v-for="(item2, index) in item.question" :key="index">
                    <div
                        class="con_que"
                        @click="clickRobot(item2.content, item2.id)"
                    >
                      <div class="czkj-question-msg">
                        {{ item2.index }}
                        {{ item2.content }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="time_r">{{ item.time }}</div>
              </div>
              <div class="info_l" v-if="item.type == 'rightinfo'">
              <span class="circle circle_l">
                <img
                    :src="item.athor.avator"
                    alt="User Avatar"
                    style="border-radius: 45px"
                />
              </span>
                <div class="con_l con_text">{{ item.content }}</div>
                <div class="time_l">{{ item.time }}</div>
              </div>
            </div>
          </div>
          <div class="setproblem">
          <textarea
              placeholder="请输入需要咨询的问题"
              style=""
              :disabled="isSendingMessage"
              id="text"
              :rows="1"
              v-model="customerText"
              @keyup.enter="sentMsg()"
          ></textarea>
            <el-button @click="sentMsg()" class="buttonsend">
              <span style="vertical-align: 4px">发送</span>
            </el-button>
          </div>
        </div>
        <div class="box" v-if="languageSelect==='en'">
          <div id="content" class="content" ref="content">
            <div v-for="(item, index) in infoEn" :key="index">
              <div class="info_r info_default" v-if="item.type === 'leftinfo'">
              <span class="circle circle_r">
                <img loading="lazy" src="@/assets/ai.png" alt=""/>
              </span>
                <div class="con_r con_text">
                  <div>{{ item.answer }}</div>
                  <div v-for="(item2, index) in item.question" :key="index">
                    <div class="con_que">
                      <div class="czkj-question-msg">
                        {{ item2.index }}
                        {{ item2.content }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="time_r">{{ item.time }}</div>
              </div>
              <div class="info_l" v-if="item.type === 'rightinfo'">
              <span class="circle circle_l">
                <img
                    :src="item.athor.avator"
                    alt="User Avatar"
                    style="border-radius: 45px"
                />
              </span>
                <div class="con_l con_text">{{ item.content }}</div>
                <div class="time_l">{{ item.time }}</div>
              </div>
            </div>
          </div>
          <div class="setproblem">
          <textarea
              placeholder="Please enter the question"
              style=""
              :disabled="isSendingMessage"
              id="text"
              :rows="1"
              v-model="customerTextEn"
              @keyup.enter="sentMsgEn()"
          ></textarea>
            <el-button @click="sentMsgEn()" class="buttonsend">
              <span style="vertical-align: 4px">Send</span>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <LoginTip
        :dialogVisibleLogin="dialogVisible"
        @hideDialog="hideDialog"
    ></LoginTip>
  </div>

</template>
<script>
// 自定义指令，用于处理点击外部区域的事件
import LoginTip from "@/components/LoginTip.vue";
import {shareUrl} from "@/utils/vxshare";
import Login from "@/views/userlog/loginDemo.vue";

const clickOutside = {
  bind(el, binding) {
    // 在元素上绑定一个点击事件监听器
    el.clickOutsideEvent = function (event) {
      // 检查点击事件是否发生在元素的内部
      if (!(el === event.target || el.contains(event.target))) {
        // 如果点击事件发生在元素的外部，则触发指令绑定的方法,将点击的event数据传过去
        binding.value(event);
      }
    };
    // 在文档上添加点击事件监听器
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    // 在元素上解除点击事件监听器
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {LoginTip, Login},
  directives: {
    "click-outside": clickOutside, // 注册自定义指令
  },
  name: "AiPop",
  data() {
    return {
      dialogVisible:false,
      isMobile:false,
      languageSelect: '',
      AiPopShow: false,
      customerText: "",
      customerTextEn: "",
      question: "",
      info: [
        {
          type: "leftinfo",
          time: this.getTodayTime(),
          name: "robot",
          answer: "我是问答小助手，请问您有什么问题，我会尽力帮您解答。",
          answerEn: "I am a question and answer assistant. If you have any questions, I will try my best to help you answer them.",
          question: [],
        },],
      infoEn: [
        {
          type: "leftinfo",
          time: this.getTodayTime(),
          name: "robot",
          answer: "I am a question and answer assistant. If you have any questions, I will try my best to help you answer them.",
          question: [],
        },
      ],
      count: 0,
      isSendingMessage: false,
      timer: "",
      uniqueId: "",
      userId: localStorage.getItem("userId"),
      titleEn: '',
      contentEn: '',
      isClicked: false,
      userInfo: '',
      isLogin: '',
      isNEWs: 0,
      isTextDisplayed: false,
      messages: [],
      newMessage: '',
      Message: '',
      token: '',
      baseURL: this.$utils.baseURL,
      avator: require("@/assets/avator.png"),
      communicateHistory: [],
      denyformShow: false,
      denyForm: {
        id: '',
        question: '',
        answer: '',
      },
      primary: '',
      submitForm: {
        id: '',
        question: '',
        answer: '',
        primary: ''
      },
      textarea: '',
      deny: 0,
      answer: '',
      options: [{
        value: '1',
        label: '新建对话'
      }, {
        value: '2',
        label: '历史记录'
      }, ],
      optionvalue: '1',
      showPopover: false,
      articles: [],
    };
  },
  created() {
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }
    this.token = localStorage.getItem('access_token');
    this.userId = localStorage.getItem("userId");
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
    console.log(this.userInfo.avatar)
    this.historylist()
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);

  },
  methods: {
    hideDialog() {
      this.dialogVisible = false;
    },
    //点击其它区域
    handleBlur() {
      // console.log("点击其它区域啦", event);
      this.AiPopShow = false;
    },
    getTodayTime() {
      // 获取当前时间
      var day = new Date();
      let seconds = day.getSeconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      } else {
        // eslint-disable-next-line no-self-assign
        seconds = seconds;
      }
      let minutes = day.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      } else {
        // eslint-disable-next-line no-self-assign
        minutes = minutes;
      }
      let time =
          day.getFullYear() +
          "-" +
          (day.getMonth() + 1) +
          "-" +
          day.getDate() +
          " " +
          day.getHours() +
          ":" +
          minutes +
          ":" +
          seconds;
      return time;
    },
    handleClose() {
      this.denyformShow = true
    },
    showDeleteButton(historyItem) {
      // 在鼠标悬停时显示删除按钮
      this.$set(historyItem, 'showDeleteButton', true);
    },
    hideDeleteButton(historyItem) {
      // 在鼠标移出时隐藏删除按钮
      this.$set(historyItem, 'showDeleteButton', false);
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    optionnew(item){
      console.log('Selected value:', item.value);
    },
    dateIfAddZero(time) {
      return time < 10 ? "0" + time : time;
    },
    // 文章索引
    clickRobot(item) {
      this.isNEWs = 1
      this.$router.push({
        path: "/articalDetail",
        query: {
          Typeid: item.primaryTypeid,
          newId: item.id },
      });
      this.$axios.get('/api/news/' + item.id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.token
        }
      },)
          .then(res => {
            this.articles = res.data.data
            const currentDate = new Date(this.articles.createTime);
            const year = currentDate.getFullYear();
            let month = this.dateIfAddZero(currentDate.getMonth() + 1)
            let day = this.dateIfAddZero(currentDate.getDate());
            const formattedDate = `${year}-${month}-${day}`;
            this.articles.dateTime = formattedDate

          })
          .catch(error => {
            console.log(error)
          })

      var vm = this
      vm.$axios.post("/systemenes/trans", {
        // params: {
        newsId: item.id,
        // },
        headers: {
          Authorization: vm.token,
        },
      })
          .then((res) => {
            // console.log(res.data.data.enTitle)
            vm.titleEn = res.data.data.enTitle
            vm.contentEn = res.data.data.enContent
          })
          .catch((error) => {
            console.log(error);
          });

    },
    // 新建对话
    newContent() {
      this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 9);
      this.messages = []
      this.count = 0
      this.isSendingMessage = false;
      this.historylist();
      if (this.languageSelect === "zh") {
        this.$notify.warning({
          title: '提示',
          message: '新建对话成功，我们将重新开始新一轮对话。',
          showClose: false,
          customClass:'notify-success',

        });
      } else {
        this.$notify.warning({
          title: 'Tips',
          message: 'If the new dialogue is successful, we will start a new round of dialogue',
          showClose: false,
          customClass:'notify-success'
        });
      }

    },
    // 历史记录轮次
    historylist() {
      // console.log("ddd")
      // console.log(parseInt(this.userId))
      this.$axios.get('api/qa/selectAll', {
        params: {
          userId: this.userId
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': parseInt(this.userId)
        }
      },)
          .then(res => {
            this.communicateHistory = res.data
            for (var i = 0; i < this.communicateHistory.length; i++) {

              const currentDate = new Date(this.communicateHistory[i].createTime)
              const month = ('0' + (currentDate.getMonth() + 1)).slice(-2)
              const day = ('0' + currentDate.getDate()).slice(-2)
              const hours = ('0' + currentDate.getHours()).slice(-2)
              const min = ('0' + currentDate.getMinutes()).slice(-2)
              const formattedDate = `${month}-${day} ${hours}:${min}`
              this.communicateHistory[i].createTime = formattedDate
              this.communicateHistory[i].isClicked = false
            }

          })
          .catch(error => {
            console.log(error)
          })

    },
    // 删除历史
    deleteHistory(item){
      console.log(item.roundId)

      console.log("删除")
    },
    // 详细的历史记录
    HisCommunicate(communicateHistory) {
      for (var i = 0; i < this.communicateHistory.length; i++) {
        this.communicateHistory[i].isClicked = false
      }

      communicateHistory.isClicked = true
      console.log(communicateHistory);
      this.$axios.get('/api/qa/selectByRoundId/' + communicateHistory.roundId, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.token
        }
      })
          .then(res => {
            console.log("详细")
            console.log(res.data)
            this.isTextDisplayed = true;
            this.messages = []
            for (var i = 0; i < res.data.length; i++) {
              this.uniqueId = communicateHistory.roundId
              this.messages.push({
                id: res.data[i].id,
                sender: 'user',
                text: res.data[i].qaQuery,
              })
              if (res.data[i].qaEvaluate) {
                res.data[i].qaEvaluate = 1
              } else {
                res.data[i].qaEvaluate = 0
              }
              this.messages.push({ // 使用保存的vm
                id: res.data[i].id,
                sender: 'ai',
                answer: res.data[i].qaAnswers,
                deny: res.data[i].qaEvaluate,
                question: res.data[i].qaQuery,
              });
            }
          })
    },
    // 点否提交
    submitDeny() {
      this.submitForm.answer = this.denyForm.answer
      this.denyformShow = false
      console.log(this.denyForm)
      console.log(this.submitForm)
      // 假设您有一个要更新的 message 对象
      this.messages = this.messages.map(message => {
        if (message.id === this.submitForm.id) {
          message.deny = 1; // 将匹配条件下的 message 的 deny 属性设置为 1
        }
        return message; // 返回更新后的 message
      });

      console.log(this.messages); // 打印更新后的 messages 数组


      this.$axios.put('/api/qa/update',
          {
            'id': this.submitForm.id,
            "qaEvaluationContent": this.denyForm.answer,
            "qaAnswers": this.denyForm.answer,
            "qaQuery": this.submitForm.primary,
            "qaEvaluate": 1
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.token
            }
          }).then(res => {
        console.log(res)
      })


    },
    // 点否重置
    resetDeny() {
      this.denyForm.question = this.submitForm.question
      this.denyForm.answer = this.submitForm.primary
    },
    changeWho() {
      this.showPopover = false
    },

    sendMessage() {

      // 清除之前的定时器
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 9);
        this.messages = []
        this.count = 0
        this.isSendingMessage = false;
        this.historylist();
        this.$notify.warning({
          title: '提示',
          message: '当前超出30min未进行对话，请刷新重试',
          showClose: true,
        });

      }, 1800000); // 等于 60,000*30 毫秒

      // 禁用发送按钮
      this.isSendingMessage = true;
      this.count++;
      if (this.uniqueId === "") {
        this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 9);
      }

      if (this.newMessage) {
        // 问题
        this.question = this.newMessage;
        this.messages.push({
          id: this.uniqueId,
          sender: 'user',
          text: this.newMessage,
        });

        // 先添加“稍后再试”消息
        this.messages.push({
          id: '',
          sender: 'ai',
          answer: "正在生成，请稍候...",
          deny: 0,
          question: this.question,
        });

        if (this.count === 21) {
          this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 9);
          this.messages = []
          this.count = 0
          this.isSendingMessage = false;
          this.historylist();
          this.$notify.warning({
            title: '提示',
            message: '当前对话轮次超出小安的能力限制啦，我们将重新开始新一轮对话。',
            showClose: false,
          });

        } else {
          this.newMessage = '';
          setTimeout(() => {
            var vm = this; // 保存this
            this.$axios
                .post('system/qa/get_answer', {
                  question: this.question,
                  round_id: this.uniqueId,
                  userId: this.userId,
                }, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                  },
                })
                .then(async function (response) {

                  var resindex = []
                  for (var i = 0; i < response.data.answer.length; i++) {
                    resindex.push({
                      index: i + 1,
                      title: response.data.answer[i]
                    })
                  }
                  vm.isSendingMessage = false;
                  // 查找索引以替换“稍后再试”消息
                  const index = vm.messages.findIndex((message) => message.id === '' && message.sender === 'ai');
                  // 若存在这样的信息
                  if (index !== -1) {
                    // 逐字显示回答
                    vm.$set(vm.messages[index], 'id', response.data.id); // 更改ID
                    // 主子输出
                    await vm.printAnswerMessage(response.data.answer, vm, index, resindex);
                    // vm.$set(vm.messages[index], 'recourse', resindex);
                    this.$nextTick(() => {
                      // 在下一个tick时滚动到底部
                      const container = this.$refs.messageContainer;
                      container.scrollTop = container.scrollHeight;
                    });
                    // console.log("huidu ")
                    // console.log(vm.messages)

                  }
                });
          }, 2000); // 2000毫秒（2秒）

        }
      } else {
        this.$notify.warning({
          title: '提示',
          message: '请输入你的问题',
          showClose: false,
        });

      }

      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    async printAnswerMessage(message, vm, index, resindex) {
      let currentIndex = 0;
      let printedMessage = '';

      const printFunction = () => {
        if (currentIndex < message.length) {
          printedMessage += message[currentIndex];
          vm.messages[index].answer = printedMessage;
          currentIndex++;
          setTimeout(printFunction, 50);
          this.$nextTick(() => {
            const container = this.$refs.messageContainer;
            container.scrollTop = container.scrollHeight;
          });
        } else {
          vm.$set(vm.messages[index], 'recourse', resindex); // 更改ID
          // 当消息逐字显示完成后，设置 isTextDisplayed 为 true
          this.isTextDisplayed = true;
          this.$nextTick(() => {
            const container = this.$refs.messageContainer;
            container.scrollTop = container.scrollHeight;
          });
        }
      };
      printFunction();
    },


    // 点否点击
    denyChange(event) {
      // 获取按钮自带的 data-info 参数
      if (event.deny === 0) {
        this.denyformShow = true
        this.submitForm.question = event.question
        this.submitForm.answer = event.answer
        this.submitForm.primary = event.answer
        this.submitForm.id = event.id
        this.denyForm.question = this.submitForm.question
        this.denyForm.answer = this.submitForm.primary
      } else {
        this.messages = this.messages.map(message => {
          if (message.id === this.submitForm.id) {
            message.deny = 0; // 将匹配条件下的 message 的 deny 属性设置为 1
          }
          return message; // 返回更新后的 message
        });

        console.log(this.messages); // 打印更新后的 messages 数组
      }


    },


    // 在printAnswerCharacterByCharacter中逐字输出答案
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
};
</script>
<style lang="scss" scoped>



.AiPop {
  position: fixed;
  top: 30vh;
  right: calc(50vw - 640px - 140px);
  z-index: 1;

  .buttonPop {
    width: 104px;
    height: 100px;
    cursor: pointer;
  }

  .pop {
    position: absolute;
    top: -10px;
    right: 104px;
    width: 420px;
    height: 320px;
    background: url(../assets/aiBg2.png) no-repeat center top;
    background-size: 100% auto;
    padding-right: 20px;
    padding-top: 82px;
    border-radius: 5px;
    overflow: hidden;
    // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    .box {
      width: 400px;
      background: #fafbff;
      height: 238px;
      padding: 20px;

      .content {
        background-color: white;
        width: 100%;
        margin:15px auto 0 auto;
        height: 95%;
        border-radius: 15px;
        //border: 5px solid black;
      }

      .communicate {

        height: 75%;
        padding: 2% 10% 1% 10%;
        border-top:black 2px solid ;
        width: 100%;
        background: white;
        font-size: 14px;
        overflow-y: auto;
        //border: 5px solid black;
      }

      .arrow-content {
        padding: 10px;
        margin-left: 1%;
        position: relative;
        display: inline-block;
        text-align: left;
        color: #000;
        border-radius: 5px;
        background-color: rgba(46, 128, 5, 0.6);
      }
      .triangle1 {

        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 8px 8px 8px; /* 调整边框宽度，0表示无顶部边框 */
        border-color: transparent rgba(46, 128, 5, 0.6)  transparent transparent ; /* 调整边框颜色 */
        top: 15px; /* 控制三角形位置 */
        left: -14px; /* 控制三角形位置 */
        transform: translateY(-50%); /* 让三角形垂直居中 */
      }
      .right {
        text-align: left;
      }


      .talk_sub {
        cursor: pointer; /* 鼠标变成手型 */
        width: 6vh;
        height: 3.5vh;
        float: left;
        margin-top: -4vh;
        bottom: 0px;
        padding: 8px;
        margin-left: 81%;
        margin-right: 5px;
        border: none;
        border-radius: 5px;
        background-image: url("../assets/send.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: #bd0022;
      }

      .talk {
        border: black 1px solid;
        display: inline-block;
        background-color: rgba(255, 255, 255);
        border-radius: 5px;
        color: #000;
        margin-right: 10%;
        width: 100%;
        padding: 5px 10px;
        word-wrap: break-word;
        position: relative; /* Add this */
      }
      /* 添加尖嘴（三角形） */
      .triangle {

        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 8px 8px 8px; /* 调整边框宽度，0表示无顶部边框 */
        border-color: transparent black   transparent transparent ; /* 调整边框颜色 */
        top: 15px; /* 控制三角形位置 */
        left: -14px; /* 控制三角形位置 */
        transform: translateY(-50%); /* 让三角形垂直居中 */
      }
      .talk_word {
        min-height: 150px;
        outline: none;
        width: 71%;
        margin-left: 10%;
        max-height: 30px;
        float: left;
        text-indent: 10px;
        border-radius: 8px;
        box-shadow: 0 2px 0 2px #8B0000;
        border: rgb(190, 0, 34) 3px solid;
        background-color: white; /* 半透明白色，alpha值为0.5 */
      }

      .select {
        background-image: url("../assets/button.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 45px;
        height: 45px;
        margin-top: auto;
        bottom: 5px;
        border: none
      }

      .select1 {
        width: 25px;
        height: 20px;
        margin-top: auto;
        bottom: 0px;
        border: none
      }

      ::v-deep .talkcontent .el-input__inner {
        width: 100%;
        background-color: #e8e8e8;
        text-align: center;
        border-color: #c0c4cc;
        color: #000
      }

      ::v-deep .el-textarea .el-input__count {
        color: #909399;
        background: #FFF;
        position: absolute;
        font-size: 18px;
        bottom: 5px;
        left: 10px;
      }

      ::v-deep .el-textarea__inner {
        border: none !important;
        font-size: 18px !important;
        height: 86px;
        width: 98%;
      }

      .talkcontent {
        margin-left: 1%;
        min-height: 82px;
        white-space: pre-wrap; /* 使文本根据内容自动换行 */
        outline: none;
        width: 98%;
        max-height: 82px;
        padding: 3px;
        float: left;
        text-indent: 10px;
        border-radius: 8px;
        overflow: auto;
        //border: black 5px solid; background-color: white; /* 半透明白色，alpha值为0.5 */
      }

      .atalk {
        margin: 10px;
        padding: 15px;
        display: flex;
        background-color: white;
        position: relative;
        align-items: flex-start; /* Align items at the top */
      }

      .btalk {
        margin: 20px;
        right: 0 !important;
        display: flex;
        flex-direction: column;
        flex-grow: 8;
      }


      .AIphoto {
        min-width: 30px;
        background-image: url("../assets/AIphoto.png");
        width: 30px;
        height: 30px;
        margin-right: 15px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 20%;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .talk {
        display: inline-block;
        background-color: rgba(255, 255, 255);
        border-radius: 5px;
        color: #000;
        margin-right: 0;
        //border: #2c3e50 1px solid; width: 100%; padding: 5px 10px; word-wrap: break-word; position: relative; /* Add this */
      }

      .title {
        color: black;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center
      }

      .whotalk {
        min-height: 88px;
        outline: none;
        max-height: 25px;
        padding: 5px;
        width: 80% !important;
        border-radius: 8px;
        border: none;
        //border-right: black 1px solid; width: 10%;
      }

      .talk_input {
        width: 100%;
        height: 5vh;
        margin: 10px auto 0;
      }


      #content {
        height: 150px;
        overflow-y: scroll;
        font-size: 14px;
        width: 100%;
        padding-right: 10px;
      }

      .circle {
        display: inline-block;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: #eff1f3;
      }

      .con_text {
        color: #333;
        margin-bottom: 5px;
      }

      .con_que {
        color: #1c88ff;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
      }

      .info_r {
        position: relative;
      }

      .circle_r {
        position: absolute;
        left: 0%;
      }

      .pic_r {
        width: 17px;
        height: 17px;
        margin: 8px;
      }

      .con_r {
        display: inline-block;
        width: 85%;
        min-height: 55px;
        background-color: #ffffff;
        border-radius: 6px;
        padding: 10px;
        margin-left: 40px;
      }

      .time_r {
        margin-left: 45px;
        color: #999999;
        font-size: 12px;
      }

      .info_l {
        text-align: right;
        color: #000;
        color: #a9ea7a;
        margin-top: 10px;
        position: relative;

        .circle_l {
          position: absolute;
          right: 0;
        }
      }

      .pic_l {
        width: 13px;
        height: 17px;
        margin: 8px 10px;
      }

      .time_l {
        margin-right: 45px;
        color: #999999;
        font-size: 12px;
        margin-top: 5px;
      }

      .con_l {
        width: auto;
        max-width: 220px;
        height: auto;
        min-height: 36px;
        background-color: #a9ea7a;
        border-radius: 6px;
        padding: 10px;
        text-align: left;
        color: #000;
        margin-right: 40px;
        display: inline-block;
        white-space: normal; /* 修改此处以支持换行 */
        overflow: hidden;
      }

      #question {
        cursor: pointer;
      }

      .setproblem {
        width: 100%;
        // height: 68px;
        // background-color: #ffffff;
        position: relative;
        margin-top: 20px;

        textarea {
          display: block;
          width: 100%;
          margin-bottom: 0px;
          color: #999999;
          padding: 0px 2px;
          box-sizing: border-box;
          // height: 64px;
          resize: none;
          outline: none;
          border-color: var(--main-color);
          border-radius: 5px;
          font-size: 12px;
          line-height: 32px;
        }

        .buttonsend {
          background: var(--main-color);
          opacity: 1;
          font-size: 16px;
          color: #ffffff;
          position: absolute;
          right: 0%;
          bottom: 0;
          cursor: pointer;
          border: none;
          width: 68px;
          height: 34px;
          line-height: 34px;
          border-radius: 5px;
          padding: 0;
        }
      }

      .czkj-item-title {
        line-height: 25px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }

      .czkj-item-question {
        cursor: pointer;
        display: block;
        padding: 8px;
        position: relative;
        border-bottom: 1px dashed #ccc;
        line-height: 20px;
        min-height: 20px;
        overflow: hidden;
      }

      .czkj-question-msg {
        float: left;
        font-size: 14px;
        color: #3163c5;
      }
    }
  }
}
.AiPop1 {
  position: absolute;
  top: 2.5vh;
  right:50px;
  z-index: 1;


  .buttonPop {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .pop {
    position: absolute;
    top: 50px;
    right: 0;
    width:250px;
    height: 320px;
    background: url(../assets/aiBg.png) no-repeat center top;
    background-size: 100% auto;
    padding-right: 10px;
    padding-top: 5px;
    border-radius: 5px;
    overflow: hidden;
    // box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    .box {
      margin-top: 60px;
      width: 250px;
      background: #fafbff;
      height: 238px;
      padding: 10px;

      #content {
        height: 150px;
        overflow-y: scroll;
        font-size: 14px;
        width: 99%;
        padding-right: 5px;
        overflow-x:hidden;
      }

      .circle {
        display: inline-block;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #eff1f3;
      }

      .con_text {
        color: #333;
        margin-bottom: 2px;
      }

      .con_que {
        color: #1c88ff;
        height: 10px;
        line-height: 30px;
        cursor: pointer;
      }

      .info_r {
        position: relative;
      }

      .circle_r {
        position: absolute;
        left: 0%;
      }

      .pic_r {
        width: 17px;
        height: 17px;
        margin: 8px;
      }

      .con_r {
        display: inline-block;
        width: 88%;
        min-height: 35px;
        background-color: #ffffff;
        border-radius: 6px;
        padding:2px;
        margin-left: 25px;
      }

      .time_r {
        margin-left: 25px;
        color: #999999;
        font-size: 10px;
      }

      .info_l {
        text-align: right;
        color: #000;
        margin-top: 10px;
        position: relative;

        .circle_l {
          position: absolute;
          right: 0;
        }
      }

      .pic_l {
        width: 13px;
        height: 17px;
        margin: 8px 10px;
      }

      .time_l {
        margin-right: 25px;
        color: #999999;
        font-size: 10px;
        margin-top: 5px;
      }

      .con_l {
        width: auto;
        max-width: 88%;
        height: auto;
        min-height: 22px;
        background-color: #a9ea7a;
        border-radius: 6px;
        padding: 10px;
        text-align: left;
        color: #000;
        margin-right: 25px;
        display: inline-block;
        white-space: normal; /* 修改此处以支持换行 */
        overflow: hidden;
      }

      #question {
        cursor: pointer;
      }

      .setproblem {
        width: 100%;
        // height: 68px;
        // background-color: #ffffff;
        position: relative;
        margin-top: 20px;
        display: flex;

        textarea {
          display: block;
          width: calc(100% - 35px);
          margin-bottom: 0px;
          color: #999999;
          padding:5px 5px;
          box-sizing: border-box;
          // height: 64px;
          resize: none;
          outline: none;
          border-color: var(--main-color);
          border-radius: 5px;
          font-size: 14px;
          line-height: 22px;
          height: 35px;
        }


        .buttonsend {
          background: var(--main-color);
          opacity: 1;
          font-size: 14px;
          color: #ffffff;
          position: absolute;
          right: 0;
          bottom: 0;
          cursor: pointer;
          border: none;
          width: 32px;
          height: 35px;
          align-items: center;
          line-height: 34px;
          border-radius: 5px;
          padding: 0;

        }
      }

      .czkj-item-title {
        line-height: 25px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }

      .czkj-item-question {
        cursor: pointer;
        display: block;
        padding: 8px;
        position: relative;
        border-bottom: 1px dashed #ccc;
        line-height: 20px;
        min-height: 20px;
        overflow: hidden;
      }

      .czkj-question-msg {
        float: left;
        font-size: 14px;
        color: #3163c5;
      }
    }
  }
}
.AiPop1:after{
  right: 20px;
  transition: height 0.3s ease; /* 使用过渡效果平滑改变高度 */
}
.el-avatar {
  width: 100%;
  height: 100%;
}

.AiPop .pop .box #content::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: var(--main-color);
}

.AiPop .pop .box #content::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.AiPop .pop .box #content::-webkit-scrollbar-track-piece {
  background-color: #fafbff;
}

@media screen and (max-width: 1570px) {
  .AiPop {
    right: 10px;
  }
}
</style>
